const style = () => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    height: "50rem",
  },
});

export default style;
