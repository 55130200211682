const style = () => ({
  container: {
    height: "33rem",
    width: "35rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
});

export default style;
