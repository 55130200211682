const style = () => ({
  container: {
    height: "auto",
    width: "35rem",
    backgroundColor: "transparent",
    padding: "1rem",
    boxSizing: "border-box",
    position: "relative",
  },
});

export default style;
