export const style = () => ({
  container: {
    width: "32rem",
    height: "6rem",
    display: "flex",
    justifyContent: "center",
  },
});

export default style;
